.headline-swiper{
    font-family: "Urbanist", sans-serif;
      font-size: 50px;
      font-weight: bolder;
      background: linear-gradient(90deg, #cd614e, #840001);
        background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
      text-align: center;
      line-height: 52px ;

}

.image-text {
  position: absolute; /* Place the text on top of the image */
  top: 0; /* Align to the top of the container */
  left: 0;
  width: 100%; /* Span the full width of the image */
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: red; /* White text for contrast */
  text-align: center; /* Center the text horizontally */
  font-size: 14px;
  font-weight: bold;
  padding: 8px; /* Adds space around the text */
  z-index: 10; /* Ensures text stays above the image */
}

// .overflow-hidden {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .flex {
//   display: flex;
//   justify-content: center; /* Ensures items are centered */
//   align-items: center; /* Ensures vertical alignment */
// }