@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  body {
    font-family: 'Urbanist', sans-serif; 
    overflow: hidden;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

