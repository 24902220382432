// .main {
//     border-top: 1px solid rgba(255, 255, 255, 0.1); // Optional: Add a top border for separation
//   }
  
  p {
    transition: color 0.3s;
  }
  

  .ss{
    background: linear-gradient(180deg, #bb7b7b, #0e0101); /* Vertical gradient from top to bottom */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  }

.main-f{
  position: relative; /* Ensures the footer stays above other content */
  z-index: 10;
}


  


@media (max-width: 768px) {
  .main{
    display: flex;
    flex-direction: column;
    height: 25vh;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .email-icon-padding{
    margin-left: 20px;
  }
  .bb{
    padding-bottom: 20px;
    margin-right: 10px;
  }
}