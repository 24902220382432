/* Container for the banner */
.banner-container {
    width: 100%;
    overflow: hidden;
    background-color: #FF000078; /* Orange background */
    color: white;
    font-size: 24px;
    padding: 10px 0;
    position: relative;
    height: 50px;
    display: flex;
    font-family: "Urbanist", sans-serif;
    align-items: center;
    bottom:10%;
    top: auto;
    &.mobile{
        bottom:auto;
        margin-top: 30%;
    }
    &.home-page{
        bottom: auto;
        margin-top: 30px;
        
        &.mobile{
            font-size: 18px;
            min-height: 30px;
        }
    }
}

/* Scrolling text */
.scrolling-text {
    position: absolute;
    white-space: nowrap;
    animation: scroll 13s linear infinite;
}

/* Keyframe for scrolling animation */
@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}