.field-name{
    color:white;
    font-size:20px;
    display: flex;
    justify-content: space-between;
    width: 20vw;
}

.combo-option-value{
    background-color: black;
    border-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:15px;
}
.combo-option-key{
    background-color: black;
    border-color: black;
    color: white;
    font-size:22px;
    &.mobile{
        font-size: 18px;
    }
}

.merch-spinner{
    &.mobile{
        justify-self: center;
        width: 100%;
    }
}




.fancy-carousel{
    display: flex;
    justify-self: center;
}

.merchs-heading{
    
    &.mobile{
        width: 80vw;
        text-align: center;
        
    }
}

.merchs-info{
    margin-left: 2.25rem;
    &.mobile{
        margin-left: 0%;
        width: 80vw;
        margin-top: 15%;
    }
}

.merch-combos{
    margin-left: 0;
}

.fancy-carousel-border{
    margin-left: 12.5%;
}
.fancy-carousel-navigators{
    margin-left: 0% !important;
}

.fancy-carousel-navigation-button{
    z-index: 2;
}

.grid-container-merch{
  display: grid;
    grid-template-columns: repeat(3, fr); /* 3 columins 
    gap: 5vw; /* Spacing between grid items */
    padding: 20px;

}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    gap: 5vw; /* Spacing between grid items */
    padding: 20px;
  }
  
  .grid-item {
    cursor: pointer;
    text-align: center;
    background-color: black;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
     
  }
  
  .grid-item:hover {

    transform: scale(1.025);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .grid-image {
    width: 100%;
    height: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .grid-item-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  
// @media (max-width: 768px) {
//     .fancy-carousel-border{
//         margin-left: 12.5%;
//     }
//     .fancy-carousel-navigators{
//         margin-left: 0% !important;
//     }
    
    
//   }


.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 items per row */
    grid-row-gap:3vh; /* Spacing between items */
    grid-column-gap: 5vw;
  }
  
  
  @media (max-width: 1024px) {
    .image-grid {
      grid-template-columns: repeat(2, 1fr); /* Medium screens: 2 items per row */
    }
  }
  
  
  .grid-item {
    position: relative;
    overflow: hidden;
  }
  
  .image-container {
    position: relative;
  }
  
  .grid-image {
    width: 100%; /* Ensures images are responsive */
    height: auto; /* Keeps image aspect ratio */
    display: block;
  }
  
  .image-text {
    position: absolute;
    top: 0; /* Aligns text to the top of the image */
    left: 0;
    width: 100%; /* Ensures text spans the width of the image */
    padding: 8px; /* Adds padding for better readability */
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    color: red; /* White text for contrast */
    text-align: center; /* Centers the text */
    font-size: 14px;
    font-weight: bolder;
    box-sizing: border-box;
  }
  

