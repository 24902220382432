@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400;700&display=swap');

body {
  background-color: #0e0101;
}

.arrow-sec-c {
  margin-top: 15px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 1px;
  /* Spacing between buttons */

  button {
    height: 2rem;
    width: 100px;
    cursor: pointer;
  }
}

.proshow-button {
  color: white;
  background-color: #840001;
  font-size: 1rem;
  font-family: 'Jost', sans-serif;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    background-color: #FB0C0C;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(1.05);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
}

.container-car-c {
  perspective: 1500px;
  /* Increase perspective for better 3D effect */
  height: 100vh;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.headline {
  // position: absolute;
  font-family: 'Urbanist', sans-serif;
  font-size: 3rem;
  font-weight: bolder;
  background: linear-gradient(90deg, #cd614e, #840001);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-align: center;
  // z-index: 5;
}


.carousel-c {
  position: relative;
  width: 70%; // Adjust width for tablets or larger screens
  height: 75vh;
  margin-top: 30px;
  transform-style: preserve-3d;
  transition: transform 1s ease;
}

.slide-wrapper-c {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.slide-c {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Hide backface */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: opacity 0.5s ease;
}

.slide-c-1{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Hide backface */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: opacity 0.5s ease;
    transform: translateZ(30vh);
  }

/* Cube Face Positions - Increase the translateZ to prevent overlapping */
.slide-c:nth-child(1) {
  transform: rotateX(0deg) translateZ(30vh);
  /* Increase translateZ */
}

.slide-c:nth-child(2) {
  transform: rotateX(90deg) translateZ(30vh);
  /* Increase translateZ */
}

.slide-c:nth-child(3) {
  transform: rotateX(180deg) translateZ(30vh);
  /* Increase translateZ */
}

.slide-c:nth-child(4) {
  transform: rotateX(270deg) translateZ(30vh);
  /* Increase translateZ */
}

/* Cube flipping animation */
.carousel-c.forward {
  transform: rotateX(calc(-90deg * var(--current-index)));
}

.carousel-c.backward {
  transform: rotateX(calc(90deg * var(--current-index)));
}

/* Combo Image Styling */
.combo-img {
  height: 88%;
  width: 82%;
  object-fit: cover;
  border-radius: 0.5rem;
  transform: scale(1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* Image Hover Effect */
// .combo-img:hover {
//   transform: scale(1.05);
// }


@media (max-width: 768px) {
  .headline {
    margin-bottom: 160px;
    &.no-user{
      margin-bottom: 0px;
    }
  }
  

  .carousel-c {
    width: 60vh;
    height: 60vh;
    margin-top: 0;
  }

  .container-car-c {
    width: 100vw;
    height: 100vw;
    /* Adjust container height */
    display: flex;
    flex-direction: column;

    gap: 40px;
    margin-bottom: 100px;



  }

  .slide-c {
    height: 75vw;
    width: 75vw;
  }

  .slide-c-1{
    height: 75vw;
    width: 75vw;
  }

  .arrow-sec-c {
    margin-top: 170px;
  }


  // .combo-img {
  //   height: 100%; 
  //   width: 100%; 
  // }


}

// .banner-homepage{
  
//   position: fixed; /* Use 'fixed' instead of 'absolute' for consistency on scroll */
//     z-index: 20;
//     background-color: rgba(255, 0, 0, 0.5); /* Same as your #FF000078 */
//     width: 100vw;
//     height: 7.5vh;
//     display: flex;
//     align-items: center;
//     overflow: hidden;
//     font-size: 20px;
//     color: #ffffff;
//     font-weight: bolder;
//     top: 95vh;
// }