@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&display=swap');
body {
  background-color: #0e0101;
}



/* Carousel Container */
.container-car {
  position: relative;
  z-index: 4;
  width: 100vw;  /* Place carousel above the animation */
}

/* Carousel Styles */
.carousel {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: 90vh;
  background: transparent;
  z-index: 5; /* Ensure content is above both the background and animation */

  .headline-p {
    font-family: 'Urbanist', sans-serif;
    font-size: 3rem;
    font-weight: bolder;
    background: linear-gradient(90deg, #cd614e, #840001);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    text-align: center;
    // z-index: 5;
  }
}

/* Slide Styles */
.slide-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  overflow: hidden;
  z-index: 4;
  perspective: 1000px; 
  width: 70%; // Adjust width for tablets or larger screens
  height: 75vh; // Ensure it has a fixed height
 // Hide overflow

margin-top: 0;

}
.combo-img {
  height: 100%; // Set height to fill the container
    width: 100%; // Set width to fill the container
    object-fit: cover; // Ensure the image covers the container
    border-radius: 0.5rem; 
  }
  
  
.slide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  transition: transform 0.5s, opacity 0.5s;
  position: absolute;
  opacity: 1;
  object-fit: cover;
  height: 80%;
  width: auto;
  z-index: 4;

  &.center {
    transform: scale(1.2);
    opacity: 1;
    z-index: 4;
    width: 80%;
  }

  &.left,
  &.right {
    transform: scale(1);
    z-index: 1;
    width: 60%;
  }

  &.hidden {
    display: none;
  }
}

/* Arrow section for buttons */
.arrow-sec {
  margin-top: 10px;
  color: white;
  display: flex;
  justify-content: space-between;

  button {
    height: 2rem;
    width: 100px;
    cursor: pointer;
  }
}

.proshow-button {
  color: white;
  background-color: #840001;
  font-size: 1rem;
  font-family: 'Jost', sans-serif;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

  &:hover {
    background-color: #FB0C0C;
    // transform: scale(1.1);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(1.05);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
}



@media (max-width: 768px) {
  .slide-wrapper {
    width: 95vw;
    height: 90vw;
    overflow: hidden;
    z-index: 4;
    margin-top: 0; 
    margin-bottom: 0; 
  }
  .carousel{
    // width:50vh;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom:150px;

    // height: 60vh;
    

  }
  .arrow-sec {
    margin-top: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
  font-size: smaller;
    button {
      height: 2rem;
      width: 90px;
      cursor: pointer;
    }
  }
  

  
.slide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  transition: smooth 1s, opacity 0.5s;
  position: absolute;
  opacity: 1;
  object-fit: cover;
  width:80vw;
  width: auto;
  z-index: 4;

  &.center {
    transform: scale(1.2);
    opacity: 1;
    z-index: 4;
    height: 65vw;
    width: 80vw;
   
  }

  &.left,
  &.right {
    transform: scale(1);
    z-index: 1;
    width: 80%;
  }

  &.hidden {
    display: none;
  }
}
}