@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700&display=swap');




h1{
  color: #ede5e5;
  
  font-family: 'Urbanist', sans-serif;
   
}

.scroll-div {
  width: 200px;
  height: 100px;
  background-color: lightblue;
  transition: transform 0.3s ease-in-out;
}
.sub-title{
  font-family: 'Urbanist', sans-serif;
 

}
@media (max-width: 768px) {
  .sub-title{

  font-size: 15px;
  line-height: 17px;
}}

