.mainpage{
  width:100vw;
  overflow: hidden;
}
.wrapper {
    position: absolute; /* Make wrapper absolute to avoid constraints */
    top: 0;
    left: 0;
    width: 100%; /* Ensure it covers the entire width */
    height: 100%; /* Cover the entire height of the viewport */
    z-index: 0; /* Keep the z-index above the carousel, but below the content */
  }
  
  .wrapper span {
    position: absolute;
    bottom: -0vh; /* Allow the span to move outside of its container's bounds */
    height: 50px;
    width: 50px;
    z-index: -5; /* Ensure it's behind the main content but visible */
    opacity: 1; /* Start with full opacity */
    background-color: #0e0101;
    box-shadow: 0 0 50px #a70505, 0 0 50px #930606, 0 0 50px #a00808, 0 0 50px #970707;
    animation: animate 10s linear infinite;
    border-radius: 50%;
  }
  
  .wrapper span:nth-child(1) {
    left: 60px;
    animation-delay: 0.6s;
  }
  
  .wrapper span:nth-child(2) {
    left: 10%;
    animation-delay: 3s;
    height: 60px;
    width: 60px;
  }
  
  .wrapper span:nth-child(3) {
    left: 20%;
    animation-delay: 2s;
  }
  
  .wrapper span:nth-child(4) {
    left: 30%;
    animation-delay: 5s;
    height: 80px;
    width: 80px;
  }
  
  .wrapper span:nth-child(5) {
    left: 40%;
    animation-delay: 1s;
  }
  
  .wrapper span:nth-child(6) {
    left: 50%;
    animation-delay: 7s;
  }
  
  .wrapper span:nth-child(7) {
    left: 60%;
    animation-delay: 6s;
    height: 100px;
    width: 100px;
  }
  
  .wrapper span:nth-child(8) {
    left: 70%;
    animation-delay: 8s;
  }
  
  .wrapper span:nth-child(9) {
    left: 90%;
    animation-delay: 6s;
    height: 90px;
    width: 90px;
  }
  
  .wrapper span:nth-child(10) {
    left: 50%;
    animation-delay: 4s;
  }
  
  @keyframes animate {
    0% {
      transform: translateY(0);
      opacity: 0.3;
    }
    50% {
      opacity: 0.5;
    }
    80% {
      opacity: 0.7;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      transform: translateY(-200vh) rotate(360deg); /* Increase height to ensure it covers more of the page */
      opacity: 0; /* Fade out */
    }
  }
  
  .mainpage {
    position: relative;
  }
  

  .banner-homepage{
  
    position: absolute; /* Use 'fixed' instead of 'absolute' for consistency on scroll */
      z-index: 20;
      background-color: rgba(255, 0, 0, 0.5); /* Same as your #FF000078 */
      width: 100vw;
      height: 7.5vh;
      display: flex;
      align-items: center;
      overflow: hidden;
      font-size: 20px;
      color: #ffffff;
      font-weight: bolder;
  }

  .banner-scrolling-text-homepage {
    position: absolute;
    white-space: nowrap;
    animation: scroll 14s linear infinite;
    will-change: transform;
}

@keyframes scroll {
    0% {
        transform: translateX(100%); /* Start outside the right edge */
    }
    100% {
        transform: translateX(-100%); /* End outside the left edge */
    }
}
