.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent; /* Initially transparent */
  position: fixed;
  width: 100vw;
  z-index: 1000;

  /* Mobile Styles */
  &.mobile {
    padding-right: 0;
    max-width: 100vw;
    z-index: 1000;
  }
  .backdrop-blur {
    backdrop-filter: blur(4px); /* You can adjust the blur amount as needed */
    background-color: rgb(0, 0, 0); 
  }
  

  
  /* Tablet Styles */
  &.tablet {
    padding-right: 0;
    max-width: 100vw;
  }

  .logo {
    width: 70px;
    height: 70px;
    /* border-radius: 50%; */
    /* background-color: #e2e2e2; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10vh;
    margin-left: 2vw;

    img {
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .menu {
    display: flex;
    font-family: 'Jost', sans-serif;
    border-radius: 20px;
    overflow: hidden;
    width: 50vw;
    height: 7vh;
    background-color: transparent; /* Use transparent for desktop */
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.4);
    justify-content: space-between; /* Ensures equal spacing between items */

    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Adjust the blur effect as needed */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Optional: softer shadow */
  
    .menu-item {
      /* cursor: pointer;
      color: white;
      transition: background-color 0.3s, color 0.3s;
      width: 25%; Ensure each menu item takes 25% width
      text-align: center;
      font-size: larger;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center; Center content vertically */

      /* &.active {
        background-color: #BF1305;
        color: white;
      }

      &:hover {
        background-color: #BF1305;
      } */
    }
  }
  .menu-item {
    cursor: pointer;
    color: white;
    transition: background-color 0.3s, color 0.3s;
    width: 25%; /* Ensure each menu item takes 25% width */
    text-align: center;
    font-size: larger;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center; /* Center content vertically */
  
    &.active {
      background-color: #BF1305; /* Change the background color for active item */
      color: white;
    }
  
    &:hover {
      background-color: #BF1305; /* Change the background color on hover */
    }
  }
  
  .sign-in {
    margin-right: 2vw;
    color: white;
    align-items: center;
    padding: 1rem;
    background-color: #BF1305;
    border-radius: 25px;
    height: 7vh;
    width: 150px;
    font-size: larger;
    font-family: 'Jost', sans-serif;
    border: none;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

    &:hover {
      background-color: #BF1305;
      transform: scale(1.1);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
    }

    &:active {
      transform: scale(1.05);
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    }
  }

  /* Tablet Styles */
  @media (max-width: 1024px) {
    .logo {
      margin-right: 5vh;
      width: 45px;
      height: 45px;
    }

    .menu {
      width: 60vw;
      height: 6vh;

      .menu-item {
        font-size: medium;
      }
    }

    .sign-in {
      margin-left: 5vh;
      height: 6vh;
      width: 120px;
      font-size: medium;
      background-color: #BF1305;
    }
  }

  /* Mobile Styles */
  @media (max-width: 768px) {
    .logo {
      margin-right: 10px;
      width: 44px;
      height: 44px;
    }

    .menu {
      width: 100vw; /* Full width for mobile */
      height: 5vh;
      justify-content: space-between; /* Maintain equal spacing */

      .menu-item {
        width: 25%; /* Ensure each item takes up 25% of the width */
        padding: 0.5rem;
        font-size: small;
      }
    }

    .sign-in {
      margin-left: 10px;
      height: 5vh;
      width: 80px;
      font-size: small;
      background-color: #BF1305;
    }
  }

  /* Ensure it works for screens smaller than 732px */
  @media (max-width: 732px) {
    .menu {
      display: none; /* Hide the menu */
    }

    .sign-in {
      margin-left: 10px;
      height: 5vh;
      width: 80px;
      font-size: small;
      background-color: #BF1305;
    }

    /* Apply blur effect and background color to the navbar */
   
    /* Semi-transparent black background */
  }
}
