

.tickets{
    min-height:100vh;
    display:flex;
    flex-direction:column;
    gap:10px;
    background-color: black;
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    .ticket{
        display:flex;
        gap:15%;
        margin-top: 18vh;
        &.mobile{
            flex-direction: column;
        }
        .ticket-image{
            aspect-ratio: 16 / 9;
            width:115vh;
            object-fit:cover;
            border-radius:8px;
            box-shadow: 0 0 5px 5px rgba(240, 242, 244, 0.7);
            margin-left: 5vw;
            margin-bottom: 20px;
            &.mobile{
                margin-left: 10vw;
                margin-right: 0%;
                width: 80%;
                align-self: center;
                height: 70vw;
            }
            
        }
        .ticket-details{
            display:flex;
            flex-direction:column;
            height:50vh;
            width: 25vw;
            gap: 5px;
            margin-right: 10vw;
            &.mobile{
                height: auto;
                width: 100%;
            }

            .proshow-name{
                color:#FF000078;
                font-size: 45px;
                font-weight: 600;
                line-height: 39px;
                text-align: left;
                &.mobile{
                    text-align: center;
                    margin-top: 10px;
                    font-size: 30px;
                }
            }

            .proshow-description{
                color:white;
                font-size:18px;
                margin-bottom: 5px;
                margin-top: 15px;

                &.mobile{
                    margin-top: 10px;
                    margin-left: 20%;
                    margin-right: 20%;
                }

                
            }
            .quantity{
                color:white;
                font-size:22px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 20vw;
                &.mobile{
                    width: 60vw;
                    margin-left: 20%;
                }
            }
            
            .price{
                color:white;
                font-size:22px;
                display: flex;
                justify-content: space-between;
                width: 20vw;
                &.mobile{
                    &.mobile{
                        width: 60vw;
                        margin-left: 20%;
                    }
                }
            }
            .ticket-type{
                color:white;
                font-size:22px;
            }

        }
        .ticket-option-value{
            background-color: black;
            border-color: black;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size:22px;
        }
        .ticket-option-key{
            background-color: black;
            border-color: black;
            color: white;
            font-size:22px;
        }
    }
}

.main-details{
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.position{    
    width: 20vw;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    &.mobile{
        width: 60vw;
        margin-left: 20%;
    }
}

.button-styling{
    background-color:#FF000078;
}

.text-white{
    color: white;
}


.margin-right-10px{
    margin-right: 10px;
}

.back-to-home-btn {
    background-color: rgba(0, 0, 0, 0.9); /* Dark background with slight transparency */
    color: white;
    font-size: 16px;
    border: 2px solid red; /* Red border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5); /* Slight red shadow */
    &.mobile{
        margin-left:10% ;

    }
  }
  
  .remove-from-cart-btn:hover {
    background-color: black; /* Solid black background on hover */
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8); /* Larger red shadow on hover */
  }

  .add-to-cart-btn {
    background-color: #8B0000; /* Dark red background */
    color: white;
    font-size: 16px;
    border: none; /* No border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;

    &.mobile{
        margin-right: 10%;
    }
    
  }

  .price-and-quantity{
    margin-top: 0px;
  }

  .highlight{
    color:rgba(255, 0, 0, 0.8);
  }

  .buttons{
    margin-top: 25px;
  }

  .hidden-part{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 35vh;
    &.mobile{
        height: auto;
    }
  }

  .quantity-button{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 2px solid #8B0000;
    display: flex;
    justify-content: center;  /* Center text horizontally */
    align-items: center;  /* Center text vertically */
    transition: background-color 0.3s ease;
    background-color: black;
  }

  .quantity-button:hover {
    background-color: #8B0000;  /* Slightly lighter color on hover */
  }

  .oat-layout-button{
    &.mobile{
        margin-left: 20%;
    }
  }
