.text-22px{
    font-size: 20px;
}



.merchDetails{
    margin-left: 5vw;
    width: 30vw;
    &.mobile{
        width: 80vw;
    }
}

.merch{
    color: white;
    margin-top: 8%;
    width: 100vw;
    display: flex;
    gap: 2.5rem;
    &.mobile{
        flex-direction: column;
    }
}

.merch-title{
    font-size: 3rem /* 48px */;
    line-height: 1;
    font-weight: 700;
    &.mobile{
        width: 80vw;

        display: flex;
        justify-self: center;
        text-align: center;
        justify-content: center;
        font-size: 2rem;
    }
}

.merch-desc{
    &.mobile{
        width: 80vw;
        display: flex;
    }
}

.merch-price{
    &.mobile{
        width: 80vw;
    }
}

.merch-quantity{
    &.mobile{
        width: 80vw;
    }
}

.merch-size{
    &.mobile{
        width: 80vw;
    }
}

.merch-customization{
    &.mobile{
        width: 80vw;
    }
}

.merch-buttons{

    margin-top: 20px;
    &.mobile{
        width: 80vw;
        margin-top: 20px;
    }
}

.see-other-btn{
    background-color: rgba(0, 0, 0, 0.9); /* Dark background with slight transparency */
    color: white;
    font-size: 16px;
    border: 2px solid red; /* Red border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5);
}

.add-to-cart-bt{
    background-color: #8B0000; /* Dark red background */
    color: white;
    font-size: 16px;
    border: none; /* No border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    cursor: pointer;

}

.merchImages{
    margin-left: 10%;
    &.mobile{
        margin-left: 10%;
    }
}

.popup-row-merch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &.mobile{
        width: 80vw;
    }
}