/* App.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: black;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    width: 400px;
  }
  
  .popup form {
    display: flex;
    flex-direction: column;
  }
  
  .popup label {
    margin-bottom: 5px;
  }
  
  .popup input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid black;
    border-radius: 4px;
    color: black;
  }
  
  .popup button {
    padding: 10px;
    background-color: darkred;
    color: black;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .popup button:hover {
    background-color: red;
    
  }
  
  .cancel-button {
    margin-top: 20px;
  }
  
  .submit-btn {
    margin-top: 20px;
  }

  .autofill-btn{
    width: 100%;
  }