


.Order-Container{
    background-color: black;
    color: white;
    margin-top: 0vh;
    position: fixed;
    overflow-y: auto;
}



.Order-Heading{
    font-family: 'Urbanist', serif;
    font-size: 3rem;
    font-weight: bold;
    background: linear-gradient(90deg, #cd614e, #840001);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    text-align: center;
    z-index: 5;       /* Standard property */
}

.Order-Section{
    margin-top: 18vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Orders-Table{
    width: 80vw;
    justify-self: center;
}

// order.scss
.Order-Container {
  .Order-Section {
    .Order-Heading {
      // You can adjust heading styles here
      font-size: 2rem;
      color: white;
      text-align: center;
      margin-bottom: 20px;
    }

    .Orders-Table {
      .scrollable-table-container {
        overflow-x: auto;  // Enables horizontal scrolling
        white-space: nowrap;  // Prevents the table from breaking into multiple lines
        -webkit-overflow-scrolling: touch;  // Smooth scrolling for mobile devices
        scrollbar-width: thin;  // Thin scrollbar (for Firefox)
        scroll-behavior: smooth;
      }

      table {
        width: 100%;
        min-width: 600px;  // Ensures table scrolls on smaller screens
        border-collapse: collapse;
        color: white;
        background-color: black;

        th, td {
          padding: 10px;
          border-bottom: 1px solid #ddd;
          text-align: center;
        }

        

        tr:nth-child {
          background-color: #222;
        }
      }
    }
  }
}
