


.Cart-Container{
    background-color: black;
    color: white;
    margin-top: 0vh;
    position: fixed;
    overflow-y: auto;
    font-family: "Urbanist", "sans-serif";
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
   
}

.text-note{
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  justify-content: center;
}

.Cart-Heading{
  font-family: 'Urbanist', serif;
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(90deg, #cd614e, #840001);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-align: center;
  z-index: 5;       /* Standard property */
  margin-top: 16vh;
}

.Cart-Section-Outer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: black;
  margin-top: 22vh;
  &.mobile{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: auto;
  }
}

.Cart-Section{
    width: 60vw;
}



.Cart-Elements{
  position: relative;
  z-index: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20%;
    &.mobile{
      margin-left: 0%;
    }
}

.terms{
  margin-left: 20%;
  display: flex;
}
.Cart-Element {
  position: relative;
    background-color:black;
    height: 200px;
    width: 40vw;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    z-index: 0;
    border-bottom: #ff0000 2px  solid;
    &.mobile{
      width: 80vw;
      padding-right: 0%;
      margin-right: 0%;
      justify-self: center;
      height: 200px;
      margin-left: 0%;
      z-index: 0;
     
    }
  }
  
  .Cart-Element-Img {
    margin-left: 25px;
    height: 150px;
    width: 150px;
    &.mobile{
      width: 100px;
      height: 100px;
      margin-left: 0px;
      z-index: 0;
    }
  }

  .Cart-Element-Data-Value{
    color: rgba(255, 0, 0, 0.8);
    width: 7rem;
    &.mobile{
      width: 3rem;
      z-index: 0;
    }
  }
  
  .Cart-Element-Image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    z-index: 0;
  }
  
  .Cart-Element-Details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 10%;
    font-size: 20px;
    &.mobile{
      padding-left: 0%;
      margin-left: 5%;
      font-size: 15px;
    }
  }
  
  .data-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: row;
  }
  
  .Cart-Element-Details-Key {
    font-size: 20px;
    font-weight: bold;
    color: #ff0000;
    width: 100px; /* Fixed width to align all keys */
  }
  
  .Cart-Element-Details-Value {
    font-size: 20px;
    color: white;
    margin-left: 10px; /* Space after the key */
  }
  
  /* Add semicolon using a pseudo-element */
  .Cart-Element-Details-Key::after {
    content: ":";
    margin: 0 10px; /* Equal spacing around the semicolon */
  }
  
  .Cart-Element-Button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #8B0000;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: white;
    transition: background-color 0.3s ease;
    background-color: black;
  }
  .Cart-Element-Button:hover{
    background-color: #8B0000; 
  }
  .Cart-Element-Details-Value-Quantity {
    display: flex;
    align-items: center;
  }
  
  .Cart-Element-Delete-Button {
    position: absolute;
    top: 10px;
    right: 10px;
    // cursor: pointer;
    color: #ff0000;
    width: 35px;
    &.mobile{
      right: 5px;
      top: 5px;

    }
  }
  

.Button-Row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Clear-Cart-Button{
    background-color: rgba(0, 0, 0, 0.9); /* Dark background with slight transparency */
    color: white;
    font-size: 16px;
    border: 2px solid red; /* Red border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5); /* Slight red shadow */
    margin-left: 5%;
    width: 50%;
    justify-content: center;
    justify-self: center;
    &.mobile{
      width:100%;
      margin-left: 0%;
    }
  }

.Clear-Cart-Button:hover{
    background-color: black; /* Solid black background on hover */
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8); /* Larger red shadow on hover */
}

.Proceed-to-Pay-Button{
    // background-color: #8B0000; /* Dark red background */
    // background-color: #602626; /* Dark red background */
    color: white;
    font-size: 16px;
    border: none; /* No border */
    padding: 10px 20px;
    border-radius: 15px; /* Rounded corners */
    outline: none;
    transition: all 0.3s ease;
    margin-right: 2.5%;
    width: 60%;
}

.Total-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 400px;
    width: 25vw;
    background-color: #FF000030;
    border-radius: 15px;
    margin-right: 10%;
    &.mobile{
      width: 80vw;
      margin-right: 10%;
      margin-left: 10%;
      margin-top: 10px;
      height: auto;
      padding-top: 10px;
    }
}

.Total-Heading{
  font-family: 'Urbanist', serif;
  font-size: 2rem;
  font-weight: bold;
  background: linear-gradient(90deg, #cd614e, #840001);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-align: center;
  z-index: 5; 
&.mobile{
  display: none;
}

}

.banner-cart{
  position: absolute; /* Use 'fixed' instead of 'absolute' for consistency on scroll */
    background-color: rgba(255, 0, 0, 0.5); /* Same as your #FF000078 */
    width: 100vw;
    height: 7.5vh;
    top: 12vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 20px;
    color: #ffffff;
    font-weight: bolder;
}

.banner-scrolling-text-cart {
  position: absolute;
  white-space: nowrap;
  animation: scroll 14s linear infinite;
  will-change: transform;
}

@keyframes scroll {
  0% {
      transform: translateX(100%); /* Start outside the right edge */
  }
  100% {
      transform: translateX(-100%); /* End outside the left edge */
  }
}

.About{
  position: relative;
    background-color: #FF000030;
    font-size: 15px;
    border-radius: 15px;
    &.mobile{
      width: 80vw;
    }
}
