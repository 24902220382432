.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Black overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures popup is above all other content */
  }
  
  .popup-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .popup-image {
    max-width: 90vw; /* Scale image to fit screen */
    max-height: 90vh;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #333;
    cursor: pointer;
  }

  .popup-link{
    color: white;
    background-color: #BF1305;
    border-radius: 10px;
    height: 5vh;
    width: 150px;
    font-size: 15px;
    font-family: 'Jost', sans-serif;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
      background-color: #881106;
      transform: scale(1.1);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
     
    }
    &.mobile{
      height: 30px;
      width: 120px;
    }
  }
  