


.inputbox-page-2-forgot{
    display: none;
}


.signup_box{
    background-image:url('../../../Images/bg1 1.png');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    width: 100%; 
    height: 100vh;
    display: flex;
    align-items: center;
}

.text-white{
    color: #ffffff;
}



.forgot-box{
    background-color: #090909;
    width: 35vw;
    height: 80vh;
    gap: 10px;  
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.mobile{
        width: 100%;
        align-items: center;
    }
    
}


.signup_box{
    display: flex;
    align-items: center;
    justify-content: center;
    .registration-form-and-image-forgot{
        background-color:#090909;
        width:65vw;
        height: 80vh;
        display: flex;
        flex-direction: row;
        border-radius: 30px;
        box-shadow: 0 0 5px 5px rgba(240, 242, 244, 0.7);
        &.mobile{
            justify-content: center;
            width: 90vw;
        }
        .registration-form-forgot{
            display: none;
            &.active{
                display: block;
                &.mobile{
                    width: 80%;
                }
            }
        }
        .sliding-image-forgot{
            
            left: 17.5vw;
            width:30vw;
            height: 80vh;
            transition:all 1s ease-in;
            &.mobile{
                display: none;
            }
            &.register{
                left: 52.5vw;
            }
            .image-slider__image-forgot {
                width:30vw;
                height: 80vh;
                position: fixed;
                left: 17.5%;
                opacity: 0;
                transition: opacity 1s ease-in-out;
                border-radius: 30px;
                object-fit: cover;
                &.active {
                    opacity: 1;
                  }
              }
        }
    }
}

.inputbox-page{
    width:100%;
}

.width100{
    width:100%;
}


.button-style-forgot{

    font-weight:bold;
    background-color: rgba(202, 26, 26, 0.83);;
    width: 70%;
    margin-left: 15%;
    padding-bottom: 10px;
    border: none;
    padding-top: 10px;
    border-radius:5px;
    margin-top: 20px;
    color:#090909;
    &.mobile{
        width: 100%;
        margin-left: 0%;
    }
}



.text-danger{
    color: red;
}


.form-styling-forgot{
    background-color: #090909;
    color: #ddd;
    border: none;
    width: 70%;
    height: 45px;
    border-radius: 15px;
    font-size: 15px;
    border-bottom: 2px solid rgba(202, 26, 26, 0.83);
    padding: 3px 5px;
    margin-left: 15%;
    &.mobile{
        width: 100%;
        margin-left: 0%;
    }
}

textarea:focus, input:focus{
    outline: none;
    }

.form-styling::placeholder {
    color: #666; /* Gray color for placeholder text */
}

.heading-forgot{
    font-weight: bold;
    margin-left: 30%;
    padding-top: 30%;
    display: flex;
    width:100%;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
    &.mobile{
        justify-content: center;
        padding-left: 0px;
        padding-top: 50%;
        margin-left: 0%;

    }
}

.registration-form-reset{
   &.mobile{
    width: 80%;
   } 
}



.inputbox-page{
    &.hide{
        display: none;
    }
}
