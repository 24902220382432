.slider-container {
  width: 100%;
  margin: auto;
  text-align: center;
}

.main-image-slider {
  position: relative;
  width: 20vw;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  &.mobile {
    width: 75vw;
    height: 100vw;
    margin-top: 10%;
    margin-left: 2.5vw;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .slider-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    z-index: 1;

    

    &.prev-btn {
      left: 10px;
    }

    &.next-btn {
      right: 10px;
    }
  }
  .slider-btn:hover{
    background-color: rgba(0, 0, 0, 0.75);
  }

  
}

.thumbnail-heading {
  text-align: left; /* Align the heading to the left */
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: 'white';

  &.mobile {
    margin-left: 10px;
  }
}




.thumbnails {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;

  &.mobile {
    justify-content: center;
    width: 80vw;
  }

  img {
    width: 20vh;
    height: auto;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 8px;
    transition: transform 0.3s ease, border-color 0.3s ease;
  }

  img:hover {
    transform: scale(1.05);
  }

  .thumbnail.active {
    border-color: #ff6600;
  }
}
