.combos {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: black;
    font-family: "Urbanist", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;

    .combo {
        display: flex;
        gap: 15%;
        margin-top: 18vh;

        &.mobile {
            flex-direction: column;
            margin-top: 10vh;
        }

        .combo-image {
            aspect-ratio: 16 / 9;
            width: 115vh;
            object-fit: cover;
            border-radius: 8px;
            box-shadow: 0 0 5px 5px rgba(240, 242, 244, 0.7);
            margin-left: 5vw;
            margin-right: 0;
            margin-bottom: 20px;

            &.mobile {
                margin-left: 10%;
                margin-right: 0%;
                width: 80%;
                align-self: center;
                height: 70vw;
            }

        }

        .combo-details {
            display: flex;
            flex-direction: column;
            height: 50vh;
            width: 25vw;
            gap: 5px;
            margin-right: 10vw;

            &.mobile {
                height: auto;
                width: 100%;
            }

            .combo-name {
                color: #FF000078;
                font-size: 45px;
                font-weight: 600;
                line-height: 39px;
                text-align: left;

                &.mobile {
                    text-align: center;
                    margin-top: 10px;
                    font-size: 30px;
                }
            }

            .combo-description {
                color: white;
                font-size: 18px;
                margin-bottom: 5px;
                margin-top: 15px;

                &.mobile {
                    margin-top: 10px;
                    margin-left: 20%;
                    margin-right: 20%;
                }
            }

            .quantity {
                color: white;
                font-size: 22px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 20vw;

                &.mobile {
                    width: 60vw;
                    margin-left: 20%;
                }
            }

            .field-name {
                color: white;
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                width: 20vw;

                &.mobile {
                    &.mobile {
                        width: 60vw;
                        margin-left: 20%;
                        font-size: 18px;
                    }
                }
            }

            .combo-type {
                color: white;
                font-size: 22px;
            }

        }

        .combo-option-value {
            background-color: black;
            border-color: black;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        }

        .combo-option-key {
            background-color: black;
            border-color: black;
            color: white;
            font-size: 22px;

            &.mobile {
                font-size: 18px;
            }
        }
    }
}

.main-details {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.position {
    width: 20vw;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    &.mobile {
        width: 60vw;
        margin-left: 20%;
    }
}

.button-styling {
    background-color: #FF000078;
}

.text-white {
    color: white;
}


.margin-right-10px {
    margin-right: 10px;
}

.back-to-home-btn {
    background-color: rgba(0, 0, 0, 0.9);
    /* Dark background with slight transparency */
    color: white;
    font-size: 16px;
    border: 2px solid red;
    /* Red border */
    padding: 10px 20px;
    border-radius: 15px;
    /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5);

    /* Slight red shadow */
    &.mobile {
        margin-left: 10%;

    }
}

.back-to-home-btn:hover {
    background-color: black;
    /* Solid black background on hover */
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8);
    /* Larger red shadow on hover */
}


.event-link {
    color: white;
    background-color: #BF1305;
    border-radius: 10px;
    height: 5vh;
    width: 100px;
    font-size: 15px;
    font-family: 'Jost', sans-serif;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    margin-top: 10px;

    &:hover {
        background-color: #881106;
        transform: scale(1.1);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);

    }

    &.mobile {
        height: 30px;
        width: 80px;
    }
}

.oat-layouts {
    &.mobile {
        width: 100px;
    }
}

.add-to-cart-btn {
    background-color: #8B0000;
    /* Dark red background */
    color: white;
    font-size: 16px;
    border: none;
    /* No border */
    padding: 10px 20px;
    border-radius: 15px;
    /* Rounded corners */
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;

    &.mobile {
        margin-right: 10%;
    }

}

.price-and-quantity {
    margin-top: 0px;
}

.highlight {
    color: rgba(255, 0, 0, 0.8);
}

.buttons {
    margin-top: 25px;
}

.hidden-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 35vh;
    font-size: 20px;

    &.mobile {
        height: auto;
    }
}

.quantity-button {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: 2px solid #8B0000;
    display: flex;
    justify-content: center;
    /* Center text horizontally */
    align-items: center;
    /* Center text vertically */
    transition: background-color 0.3s ease;
    background-color: black;
}

.quantity-button:hover {
    background-color: #8B0000;
    /* Slightly lighter color on hover */
}

.combo-input {
    background-color: black;
    border: 2px solid #FF000078;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5);
    color: white;
    text-indent: 10px;
}


.combo-input::placeholder {
    color: grey;
    font-size: medium;
}

.popup-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &.mobile {
        width: 60vw;
        margin-left: 20%;
    }
}

.timer {
    width: 15vw;
    height: 15vh;
    border: 2px solid red;
    border-radius: 15px;

    margin-bottom: 2%;
    margin-top: 2%;

    box-shadow: 0px 0px 5px rgba(255, 0, 0, 0.5);

    &.mobile {
        width: 80vw;

    }
}



.otherCombos {
    height: 20vh;
    max-width: 53vw;
    margin-right: 2%;
    margin-left: 2%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    margin-left: 5vw;

    &.mobile {
        width: 90vw;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    h3 {
        color: white;

        font-weight: bold;
    }

    .combo-container {
        display: flex;
        gap: 1rem;
        overflow-x: auto; // Horizontal scrollbar
        overflow-y: hidden; // Prevent vertical scrollbar
        padding: 0.5rem 0;
        scroll-behavior: smooth;
        height: 100%; // Match container height to avoid vertical scroll

        &.mobile {
            width: 90vw;
        }

        .otherCombo {
            flex: 0 0 auto; // Ensures each item maintains its width
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(16 / 9 * 15vh); // Aspect ratio width

            .otherComboImg {
                height: 15vh;
                width: 100%; // Matches the width of .otherCombo
                border-radius: 10px;
                object-fit: cover;
            }
        }
    }
}