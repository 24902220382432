.inputbox-page-2 {
    display: none;
}



.signup_box {
    background-image: url('../../Images/bg3 2.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}

// .fields{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }


.selected-option {
    background-color: #090909;
    color: #ddd;
}


.text-white {
    color: #ffffff;
    cursor: pointer;
}

.login-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.registration-box {
    background-color: #090909;
    width: 35vw;
    height: 80vh;
    gap: 0px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s ease;

    &.mobile {
        width: 80vw;
        align-items: center;
        justify-content: center;
    }

}

.banner {
    position: fixed; /* Use 'fixed' instead of 'absolute' for consistency on scroll */
    z-index: 20;
    background-color: rgba(255, 0, 0, 0.5); /* Same as your #FF000078 */
    width: 100vw;
    height: 7.5vh;
    top: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 20px;
    color: #ffffff;
    font-weight: bolder;
}

.banner-scrolling-text {
    position: absolute;
    white-space: nowrap;
    animation: scroll 14s linear infinite;
    will-change: transform;
}

@keyframes scroll {
    0% {
        transform: translateX(100%); /* Start outside the right edge */
    }
    100% {
        transform: translateX(-100%); /* End outside the left edge */
    }
}



.signup_box {
    display: flex;
    align-items: center;
    justify-content: center;

    .registration-form-and-image {
        background-color: #090909;
        width: 65vw;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        border-radius: 30px;
        box-shadow: 0 0 5px 5px rgba(240, 242, 244, 0.7);

        &.mobile {
            justify-content: center;
            width: 90vw;
        }

        &.register {
            justify-content: flex-start;

            &.mobile {
                justify-content: center;
            }
        }

        .registration-form {
            display: none;

            &.active {
                display: block;
            }
        }

        .sliding-image {
            position: absolute;
            left: 17.5vw;
            width: 30vw;
            height: 80vh;
            z-index: 1;
            transition: all 1s ease-in;

            &.mobile {
                display: none;
            }

            &.register {
                left: 52.5vw;
            }

            .image-slider__image {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 1s ease-in-out;
                border-radius: 30px;
                object-fit: cover;

                &.active {
                    opacity: 1;
                }
            }
        }
    }
}

.inputbox-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.width100 {
    width: 100%;
}

.Prev-and-Register {
    display: flex;
    justify-content: space-between;
    width: 70%;
    gap: 50px;

    &.mobile {
        gap: 10px;
    }

}

.register-and-forgot-password{
    display: flex;
    justify-content: space-between;
    width: 70%;
    font-size: 12px;
    margin-top: 3%;
    &.mobile {
        font-size: 12px;
    }
}

.dont-have-account {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
    margin-left: 30%;
    margin-top: 3%;

    &.login-here {
        margin-left: 15%;
    }

    &.mobile {
        font-size: 12px;
    }
}

.note{
    display: flex;
    justify-content: flex-start;
    width: 70%;
    font-size: 12px;
    margin-left: 15%;
    margin-top: 3%;
}

.button-style {
    font-weight: bold;
    background-color: rgba(202, 26, 26, 0.83);
    width: 70%;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 5px;
    color: #090909;
    border: none;
    margin-left: 15%;
}



.text-danger {
    color: red;
}


.form-styling {
    background-color: #090909;
    color: #ddd;
    border: none;
    width: 70%;
    height: 45px;
    border-radius: 15px;
    font-size: 15px;
    border-bottom: 2px solid rgba(202, 26, 26, 0.83);
    padding: 3px 5px;
    
}



textarea:focus, input:focus{
  outline: none;
  }
  
  .selected-option {
    width: auto; /* Set width of the select */
    padding: 5px; /* Adjust padding */
    background-color: #090909;
    color: white;
    border: 1px solid #090909;
    border-radius: 5px;
  }
  
  .option {
    background-color: #090909;
    color: white;
  }
  

.heading {
    padding-left: 15%;
    padding-top: 18%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
    font-weight: bold;

    &.mobile {
        justify-content: center;
        padding-left: 0px;
        padding-top: 0%;
    }
}

.highlight{
    color: white;
    text-decoration: none;
}



.login-heading {
    padding-left: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 20px;
    padding-top: 25%;
    width: 100%;

    &.mobile {
        justify-content: center;
        padding-left: 0px;
        padding-top: 10%;
    }
}


.sub-heading {
    width: 100%;
    padding-left: 15%;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;

    &.mobile {
        justify-content: center;
        padding-left: 0px;
    }
}

.login-subheading {
    display: flex;
    padding-left: 15%;
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.mobile {
        justify-content: center;
        padding-left: 0%;

    }
}

.inputbox-page {
    &.hide {
        display: none;
    }
}