@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
.faq-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5vh;
    width: 100vw;
    // height: 400px;
    &.mobile{
        margin-top: 15vh;
    }

}



.faq-content {
    width: 50vw;
    z-index: 4;
}

.faq-item {
   margin-top: 50px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 30px;
   padding: 8px;
    padding-left: 20px;
    font-family: 'Raleway', sans-serif;
    color: #cdcdcd;
    font-size:20px;
    text-align: left;
    font-weight: lighter;
    border-bottom: 2px solid #840001;
}

.faq-question {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.arrow {
    margin-left: 10px;
    transition: transform 0.3s;
    color:  #840001;
    ;
}
.circle{
    width:80px;
    height: 50px;
    margin-bottom: 20px;
}

.faq-question {
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.arrow {
    margin-left: 10px;
    transition: transform 0.3s;
}
.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.3s ease;
    padding: 0 20px;
    color: #E7D5C6;
}

.faq-item.active .faq-answer {
    max-height: 200px; 
    padding: 10px 20px;
}

.faq-item.active .arrow {
    transform: rotate(180deg);
}
.faq-box {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}

.faqq { 
    font-weight: bolder;
    font-size: 160px;
    background: linear-gradient(180deg, #840001, #310303); /* Vertical gradient from top to bottom */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent; /* Ensure the text is transparent to show the gradient */
  text-align: center;
}

.faq-h{
    display: flex;
    flex-direction: column;
    margin-right:10vw ;
    border-bottom: 2px solid #840001 ;
    align-items: center;
}

@media (max-width: 768px) {
    .faq-content {
        width: 98%;
        // margin-right: 10px;
    }

   
.faq-item {
    margin-top: 50px;
     box-sizing: border-box;
     border-radius: 4px;
     margin-bottom: 0px;
    justify-content: center;
     padding-left: 10px;
     font-family: 'Raleway', sans-serif;
     color: #840001;
     font-size:17px;
     text-align: left;
     font-weight: lighter;
     border-bottom: 2px solid #514B46;
 }
 
    .faq-head {
        font-size: 48px;
        text-align: center;
    }

    .faq-box {
        flex-direction: column;
        
    }

    .faq-image {
        display: none;
    }
    .faq-h{
        margin-left: 30px;
        width: 90%;
    }
    .faq-container{
        margin-bottom: 5vh;
    }
    .faqq{
        font-weight: bolder;
        font-size: 130px;
    }
    .faq-question{
       height: fit-content;
    }
}
